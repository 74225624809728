import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAgendaService = serviceBase({
	request: () => axiosClient.get('agenda')
});

export const signUserToShiftService = serviceBase({
	request: (values) => axiosClient.put('agenda/enroll/client', {...values})
});


export default getAgendaService;