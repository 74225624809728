  
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getServicesService = serviceBase({
	request: () => axiosClient.get('service')
});

export const getIntervalDatesService = serviceBase({
	request: (data) => axiosClient.post(`service/generate-interval/${data.serviceId}`, {
		dateSelected: (data.dateSelected.toDateString())
	})
});

export default getServicesService;