import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestAgenda: [],
  successfulAgenda: ["payload"],
  errorAgenda: ["error"],
  successfulSignUser: ["payload"],
});

export const AgendaActionCreators = Creators;

const initialState = {
  loading: false,
  agenda: [],
  error: false,
  success: false,
  message: "",
  userSignUpInfo: {
    agendaId: "",
    name: "",
    phoneNumber: "",
    email: "",
    selectedInterval: "",
    serviceTitle: "",
  },
};

const requestAgenda = (state = initialState) => ({
  ...state,
  loading: true,
  error: false,
  success: false,
});

const successfulAgenda = (state = initialState, action) => {
  const { agenda } = action.payload.result;
  //
  return {
    ...state,
    loading: false,
    error: false,
    agenda,
  };
};

const errorAgenda = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: true,
  success: false,
  message: action.error.message,
});

const successfulSignUser = (state = initialState, action) => {
  const userSignUpInfo = action.payload.result;
  return {
    ...state,
    loading: false,
    error: false,
    success: true,
    message: "Te has inscrito correctamente",
    userSignUpInfo: userSignUpInfo,
  };
};

export default createReducer(initialState, {
  [Types.REQUEST_AGENDA]: requestAgenda,
  [Types.SUCCESSFUL_AGENDA]: successfulAgenda,
  [Types.ERROR_AGENDA]: errorAgenda,
  [Types.SUCCESSFUL_SIGN_USER]: successfulSignUser,
});
