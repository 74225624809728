  
import { ServicesActionCreators } from '../reducers/servicesReducer';
import {
	getServicesService,
	getIntervalDatesService
} from '../services/services';

export const getServices = data => async dispatch => {
	dispatch(ServicesActionCreators.requestServices());
	try {
		const response = await getServicesService();
		dispatch(
			ServicesActionCreators.successfulServices({
				result: response
			})
		);
	} catch (e) {
		dispatch(ServicesActionCreators.errorServices(e));
	}
};

export const getIntervalDates = data => async dispatch => {
	dispatch(ServicesActionCreators.requestServices());
	try {
		const response = await getIntervalDatesService(data);
		dispatch(
			ServicesActionCreators.successfulFetchIntervalDates({
				result: response
			})
		);
	} catch (e) {
		dispatch(ServicesActionCreators.errorServices(e));
	}
};
